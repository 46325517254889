import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AccountHolder,
  updateAllowedPaymentMethods,
  UpdateAllowedPaymentMethodsBody,
} from "../../../services/accountService";
import useCreateAccountHolderNotesMutation from "./useCreateAccountHolderNotesMutation";
import paymentMethods from "../../../data/paymentMethods.json";

export default function useUpdateAllowedPaymentMehtodsMutation(
  accountHolderCode: string
) {
  const queryClient = useQueryClient();
  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  return useMutation<string, Error, UpdateAllowedPaymentMethodsBody>(
    (body) => updateAllowedPaymentMethods(body),
    {
      async onSuccess(_, updatedAllowedPaymentMethods) {
        const accountConfigData = queryClient.getQueryData<AccountHolder>([
          "accountConfig",
          accountHolderCode,
        ]);

        queryClient.setQueryData(["accountConfig", accountHolderCode], {
          ...accountConfigData,
          allowedPaymentMethods: updatedAllowedPaymentMethods.allowedPayments,
        });

        const previousAllowedPaymentMethods =
          accountConfigData?.allowedPaymentMethods.map((pm) =>
            findPaymentMethodLabel(pm.allowedPaymentMethod)
          );
        const newAllowedPaymentMethods =
          updatedAllowedPaymentMethods.allowedPayments.map((pm) =>
            findPaymentMethodLabel(pm.allowedPaymentMethod)
          );

        if (previousAllowedPaymentMethods && updatedAllowedPaymentMethods) {
          await createNote({
            accountHolderCode,
            noteContent: [
              {
                fieldName: "allowedPaymentMethods",
                fromValue:
                  previousAllowedPaymentMethods.length > 0
                    ? previousAllowedPaymentMethods?.join(", ")
                    : "none",
                toValue:
                  newAllowedPaymentMethods.length > 0
                    ? newAllowedPaymentMethods.join(", ")
                    : "none",
              },
            ],
          });

          queryClient.invalidateQueries(["accountConfig", accountHolderCode]);
        }
      },
    }
  );
}

function findPaymentMethodLabel(value: string) {
  return paymentMethods.find((pm) => pm.value === value)?.label ?? "";
}
