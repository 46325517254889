import { DateTime } from "luxon";
import {
  DefaultLedgerPostBody,
  DefaultListResponse,
  DefaultPostBody,
  FilterType,
  OrderType,
  PageInfo,
  SortInfo,
} from "../types";
import { httpCall } from "../utils/http";
import {
  formatTransaction,
  formatTransactionEvent,
} from "../utils/transaction";
import { getCurrentUserAccountWithExtraFields } from "../utils/userAccounts";
import { longDateFormat } from "../utils/dateTime";
import { formatAmount } from "../utils/currency";
import { TimeZone } from "./accountService";

export const dateFilterKeys = [
  "Last1Hour",
  "Last24Hours",
  "Last7Days",
  "Last30Days",
  "Custom",
];
export enum DateFilterOption {
  Last1Hour = "Last 1 hour",
  Last24Hours = "Last 24 hours",
  Last7Days = "Last 7 days",
  Last30Days = "Last 30 days",
  Custom = "Custom",
}
export type DateFilterType = keyof typeof DateFilterOption;

export type TransactionType = "Payment" | "Refund" | "Chargeback";

export type TransactionStatusType =
  | "Authorised"
  | "Cancelled"
  | "Settled"
  | "SentForSettle"
  | "Settle Scheduled"
  | "Refund Scheduled"
  | "Refunded"
  | "Error"
  | "CancelFailed"
  | "CaptureFailed"
  | "RefundFailed"
  | "RefundReversed"
  | "Chargeback"
  | "Failed";

export type TransactionsFilter = {
  accountHolderCode?: string;
  amount: {
    from: number | null;
    to: number | null;
  };
  cardOrAccountNumber: string;
  pspReference: string;
  merchantReference: string;
  date: {
    type: DateFilterType;
    from: DateTime | null;
    to: DateTime | null;
  };
  transactionType: TransactionType | null;
  status: TransactionStatusType[];
  hideZeroUsdTransactions: boolean;
};
export type TransactionsFilterWithPageAndSortInfo = TransactionsFilter &
  PageInfo &
  SortInfo;

export type Transaction = {
  accountCode: string;
  accountHolderCode: string;
  amountCurrency: string;
  amountValueRaw: number;
  amountValue: string;
  authCode: string;
  business: string;
  id: string;
  location: string;
  lockReason: string | null;
  merchantAccount: string;
  merchantReference: string;
  originalReference: string;
  paymentDetails: TransactionDetail | null;
  paymentEvents: TransactionEvent[];
  paymentLifeCycleStatus: string;
  paymentLifeCycleStatusDate: string;
  paymentLifeCycles: TransactionLifecycle[];
  paymentNotes: TransactionNote[];
  paymentMethod: string;
  paymentOwnerName: string;
  paymentTypeNumber: string;
  pspReference: string;
  refundAmount: string;
  refundAmountRaw: number;
  transactionCreatedDate: string;
  accountHolderTimeZone?: TimeZone;
};

export type TransactionDetail = {
  avs: string;
  bankAccountNumber: string;
  billingAddressCity: string;
  billingAddressCountry: string;
  billingAddressHouseNumberOrName: string;
  billingAddressPostalCode: string;
  billingAddressStateOrProvince: string;
  billingAddressStreet: string;
  cardHolderName: string;
  cardNumber: string;
  cardType: string;
  commissionAmount: string;
  cvc: string;
  dsAuthenticated: string;
  dsAuthenticatedResponse: string;
  dsLiabilityShift: string;
  dsOffered: string;
  dsOfferedResponse: string;
  dsVersion: string;
  expiryDate: string;
  id: string;
  issuerCountry: string;
  ownerName: string;
  pspReference: string;
  shopperCountry: string;
  shopperEmail: string;
  shopperIp: string;
  shopperReference: string;
  splitMarketPlace: string;
  technologyFee: string;
  technologyFeeRaw: number;
  convenienceFeeAmount: string;
  creditCardProcessingFeeAmount: string;
  transactionFeeAmount: string;
  fundingSource: string;
};

export type TransactionLifecycle = {
  companyAccount: string;
  merchantAccount: string;
  pspReference: string;
  merchantReference: string;
  paymentMethod: string;
  journalCreatedDate: string;
  timeZone: string;
  mainCurrency: string;
  mainAmount: string;
  mainAmountRaw: number;
  journalType: string;
  paymentCurrency: string;
  receivedAmount: string;
  authorisedAmount: string;
  capturedAmount: string;
  settlementCurrency: string;
  payableAmount: string;
  commissionAmount: string;
  markupAmount: string;
  schemeFees: string;
  interchangeFee: string;
  processingFeeCurrency: string;
  processingFee: string;
  auditUser: string;
  paymentMethodVariant: string;
  modificationMerchantReference: string;
  riskPremium: string;
  lineNumber: string;
  utcTime: string;
};

export type TransactionEvent = {
  accountCode: string;
  accountHolderCode: string;
  accountNumber: string;
  amountCurrency: string;
  amountValue: string;
  amountValueRaw: number;
  cardHolderName: string;
  cardSummary: string;
  consumerName: string;
  eventType: string;
  exceptionAmountCurrency: string;
  exceptionAmountValue: string;
  exceptionEventStatus: string;
  id: string;
  merchantAccount: string;
  merchantReference: string;
  originalReference: string;
  paymentMethod: string;
  pspReference: string;
  reason: string;
  transactionCreatedBy: string;
  transactionCreatedDate: string;
  createdBy: string;
  updatedBy: string;
  success: boolean;
};

export type TransactionNote = {
  createdBy: string;
  dateCreated: string;
  noteText: string;
};

export type AddNoteResponse = TransactionNote & {
  pspReference: string;
  Message?: string;
};

export type AddNoteBody = {
  pspReference: string;
  noteText: string;
};

export type CancelTransactionResponse = {
  pspReference: string;
  paymentPspReference: string;
};

export type CancelTransactionBody = {
  pspReference: string;
  merchantAccount: string;
  reference?: string;
};

export type RefundTransactionResponse = {
  pspReference: string;
  paymentPspReference: string;
};

export type RefundTransactionBody = {
  pspReference: string;
  amount: number;
  currency: string;
  merchantAccount: string;
  reference?: string;
};

export type GetTransactionsListResponse = DefaultListResponse<Transaction>;

export type GetExceptionsListResponse = DefaultListResponse<TransactionEvent>;

export type GetReportBody = {
  accountHolderCode: string;
  reportDate: string;
  balancePlatformAccountHolderId?: string;
};

export type DepositSummaryReportBody = {
  accountHolderCode: string;
  reportDate: string;
};

export type DepositDetailReportBody = {
  accountHolderCode: string;
  payoutMethod: string;
  reportDate: string;
};

type DailyReportMethod = {
  type: string;
  charges: number;
  chargesAmount: number;
  refunds: number;
  refundsAmount: number;
  chargebacks: number;
  chargebacksAmount: number;
};

export type DailyReportResponse = {
  accountHolderCode: string;
  processingDate: string;
  sales: number;
  refunds: number;
  chargebacks: number;
  netSales: number;
  methods: DailyReportMethod[];
  originalCharges: number;
  techFees: number;
  convenienceFees: number;
  totalCharges: number;
  processingFees: number;
  deposit: number;
  transactions: number;
  currencyCode: string;
};

export type DailyReportNoContentResponse = DailyReportResponse & {
  noContent: boolean;
};

export type MonthlyReportResponse = {
  accountHolderCode: string;
  processingDate: string;
  sales: number;
  refunds: number;
  chargebacks: number;
  netSales: number;
  summaryCharges: number;
  summaryChargesTotal: number;
  summaryRefunds: number;
  summaryRefundsTotal: number;
  summaryChargeback: number;
  summaryChargebackTotal: number;
  originalCharges: number;
  techFees: number;
  convenienceFees: number;
  totalCharges: number;
  processingFees: number;
  deposit: number;
  transactions: number;
  totalFeesDue: number;
  totalFeesPaid: number;
  methods: MonthlyReportMethod[];
  processingFeeList: MonthlyReportProcessingFee[] | null;
  additionalServiceFeeList: MonthlyReportProcessingFee[] | null;
  currencyCode: string;
};

type MonthlyReportMethod = {
  type: string;
  charges: number;
  chargesAmount: number;
  refunds: number;
  refundsAmount: number;
  chargebacks: number;
  chargebacksAmount: number;
};

type MonthlyReportProcessingFee = {
  feeType: string;
  quantity: number;
  perTransactionFee: number;
  total: number;
};

export type MonthlyReportNoContentResponse = MonthlyReportResponse & {
  noContent: boolean;
};

async function getTransactionFilterBody(
  filter: TransactionsFilterWithPageAndSortInfo
) {
  const { type: currentUserAccountType, accountHolderCode } =
    await getCurrentUserAccountWithExtraFields();
  const body: DefaultLedgerPostBody = {
    pageInfo: {
      pageNumber: filter.pageNumber,
      pageSize: filter.pageSize,
      filters: [
        {
          name: "TransactionCreatedDate",
          value: [filter.date.from],
          type: FilterType.GreaterThanOrEqual,
        },
        {
          name: "TransactionCreatedDate",
          value: [filter.date.to],
          type: FilterType.LessThanOrEqual,
        },
      ],
      orders: filter.sort.map((x) => ({
        name: x.id,
        type: x.desc ? OrderType.Desc : OrderType.Asc,
      })),
    },
    ianaTimezone: DateTime.now().toFormat("z"),
  };

  if (filter.accountHolderCode) {
    body.pageInfo.filters.push({
      name: "AccountHolderCode",
      value: [filter.accountHolderCode],
      type: FilterType.Contains,
    });
  }

  if (filter.transactionType) {
    body.pageInfo.filters.push({
      name: "EventType",
      value:
        filter.transactionType === "Chargeback"
          ? ["Chargeback", "Chargeback_reversed"]
          : [filter.transactionType],
      type: FilterType.Equals,
    });
  }

  if (filter.status.length > 0) {
    body.pageInfo.filters.push({
      name: "PaymentLifeCycleStatus",
      value: filter.status,
      type: FilterType.Equals,
    });
  }

  if (filter.amount.from !== null) {
    body.pageInfo.filters.push({
      name: "AmountValue",
      value: [filter.amount.from],
      type: FilterType.GreaterThanOrEqual,
    });
  }

  if (filter.amount.to !== null) {
    body.pageInfo.filters.push({
      name: "AmountValue",
      value: [filter.amount.to],
      type: FilterType.LessThanOrEqual,
    });
  }

  if (filter.cardOrAccountNumber) {
    body.pageInfo.filters.push({
      name: "TransactionOwnerNumber",
      value: [filter.cardOrAccountNumber],
      type: FilterType.Contains,
    });
  }

  if (filter.pspReference) {
    body.pageInfo.filters.push({
      name: "OriginalReference",
      value: [filter.pspReference],
      type: FilterType.Contains,
    });
  }

  if (filter.merchantReference) {
    body.pageInfo.filters.push({
      name: "MerchantReference",
      value: [filter.merchantReference],
      type: FilterType.Contains,
    });
  }

  if (filter.hideZeroUsdTransactions) {
    body.pageInfo.filters.push({
      name: "AmountValue",
      value: ["0"],
      type: FilterType.NotEquals,
    });
  }

  if (currentUserAccountType === "Customer") {
    body.pageInfo.filters.push({
      name: "AccountHolderCode",
      value: [accountHolderCode],
      type: FilterType.Contains,
    });
  }

  return body;
}

export async function getTransactionsWithDetailsList(
  filter: TransactionsFilterWithPageAndSortInfo
) {
  return httpCall<GetTransactionsListResponse, DefaultLedgerPostBody>("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/getTransactionsWithDetails`,
    errorMessage:
      "Unable to load transactions with details list. Please try again.",
    body: await getTransactionFilterBody(filter),
    mapFn: (data) => ({
      ...data,
      pageElements: data.pageElements.map(formatTransaction),
    }),
  });
}

export async function getTransactionDetail(pspReference: string) {
  return httpCall<Transaction>("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/getPaymentDetails`,
    errorMessage: "Unable to load transaction detail. Please try again.",
    body: {
      name: "PspReference",
      value: [pspReference],
      type: FilterType.Equals,
    },
    mapFn: formatTransaction,
  });
}

export async function addNote(pspReference: string, noteText: string) {
  return httpCall<AddNoteResponse, AddNoteBody>("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/addCsrNotes`,
    errorMessage: "Unable to add the note. Please try again.",
    body: {
      pspReference,
      noteText,
    },
  });
}

export async function cancelTransaction({
  pspReference,
  merchantAccount,
  reference,
}: CancelTransactionBody) {
  return httpCall<CancelTransactionResponse>("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/cancel`,
    errorMessage: "Unable to cancel transaction. Please try again.",
    body: {
      pspReference,
      merchantAccount,
      reference,
    },
  });
}

export async function refundTransaction({
  pspReference,
  merchantAccount,
  reference,
  amount,
  currency,
}: RefundTransactionBody) {
  return httpCall<RefundTransactionResponse>("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/refund`,
    errorMessage: "Unable to refund transaction. Please try again.",
    body: {
      pspReference,
      reference,
      merchantAccount,
      modificationAmount: {
        value: amount,
        currency,
      },
      waitResponse: false,
    },
  });
}

export async function getDailyReport(body: GetReportBody) {
  return await httpCall<
    DailyReportResponse,
    unknown,
    DailyReportNoContentResponse
  >("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/dailyReports`,
    errorMessage: `Unable to get daily report for account holder ${body.accountHolderCode} and date ${body.reportDate}. Please try again.`,
    body,
    mapFn: (data) => {
      return {
        ...data,
        noContent: Boolean(!data),
      };
    },
  });
}

export async function getMonthlyReport(body: GetReportBody) {
  return await httpCall<
    MonthlyReportResponse,
    unknown,
    MonthlyReportNoContentResponse
  >("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/monthlyReport`,
    errorMessage: `Unable to get monthly report for account holder ${body.accountHolderCode} and date ${body.reportDate}. Please try again.`,
    body,
    mapFn: (data) => {
      return { ...data, noContent: Boolean(!data) };
    },
  });
}

export type ReportType = "daily" | "monthly";

export type ReportTransaction = {
  pspReference: string;
  accountHolderCode: string;
  business: string;
  location: string;
  merchantReference: string;
  merchantAccount: string;
  transactionCreatedDate: string;
  paymentOwnerName: string;
  paymentTypeNumber: string;
  paymentMethod: string;
  paymentLifeCycleStatus: string;
  fundingSource: string;
  amountCurrency: string;
  businessNet: string;
  creditCardProcessingFeeAmount: string;
  technologyFee: string;
  amountValue: string;
  transactionType: string;
  transactionFeeAmount: string;
  payoutReportDate: string;
  timeZoneCode: string;
  originalChargeAmount: string;
  convenienceFeeAmount: string;
  refundAmount: string;
  processingFees: string;
  chargeBackAmount: string;
};

export type ReportCSVResponse = ReportTransaction[];

export async function getReportCSV(type: ReportType, body: GetReportBody) {
  return await httpCall<ReportCSVResponse>("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/${type}ReportCSV`,
    errorMessage: `Unable to get ${type} report CSV.`,
    body,
  });
}

export type GetTransactionLedgerBody = TransactionsFilter & PageInfo & SortInfo;

export type TransactionLedgerItem = {
  pspReference: string;
  originalReference: string;
  accountHolderCode: string;
  merchantReference: string;
  merchantAccount: string;
  transactionCreatedDate: string;
  transactionOwnerName: string;
  transactionOwnerNumber: string;
  amountCurrency: string;
  amountValue: string;
  eventType: string;
  paymentLifeCycleStatus: string;
  business: string;
  location: string;
  paymentMethod: string;
  timeZoneCode: string;
};

export type GetTransactionLedgerResponse =
  DefaultListResponse<TransactionLedgerItem>;

export async function getTransactionLedger(body: GetTransactionLedgerBody) {
  return await httpCall<GetTransactionLedgerResponse>("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/getTransactionLedger`,
    errorMessage: `Unable to get transaction ledger data.`,
    body: await getTransactionFilterBody(body),
    mapFn: (data) => ({
      ...data,
      pageElements: data.pageElements.map((x) => {
        return {
          ...x,
          transactionCreatedDate: DateTime.fromISO(x.transactionCreatedDate, {
            setZone: true,
          }).toFormat(longDateFormat),
          amountValue: formatAmount(x.amountValue),
        };
      }),
    }),
  });
}

export type GetMonthlyFeesDueBody = {
  merchantAccountId: string;
  reportDate: string;
};

export type MonthlyFeesDueItem = {
  accountHolderCode: string;
  businessName: string;
  amount: string;
  transactionDate: string;
};

export type GetMonthlyFeesDueResponse = MonthlyFeesDueItem[];

export async function getMonthlyFeesDue(body: GetMonthlyFeesDueBody) {
  return await httpCall<GetMonthlyFeesDueResponse>("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/monthlyFeesDue`,
    errorMessage: `Unable to get monthly fees due data.`,
    body,
    mapFn: (data) =>
      data.map((x) => {
        return {
          ...x,
          amount: formatAmount(x.amount),
        };
      }),
  });
}

type PayoutSummaryDeposit = {
  bookingDate: string;
  amount: number;
  currency: string;
  status: string;
  filterDate: string;
};

type PayoutSummaryAccount = {
  accountCode: string;
  location: string;
  deposits: PayoutSummaryDeposit[];
  totalAmount: number;
};

export type GetDepositSummaryReportResponse = {
  accountHolderCode: string;
  processingDate: string;
  timeZoneCode: string;
  deposits: PayoutSummaryDeposit[];
  payoutMethod: string;
  totalAmount: number;
}[];

export type GetTTMDataResponse = {
  transactionCount: string;
  ttm: string;
  change: string;
};

export type GetPayoutDetailReportBody = {
  accountHolderCode: string;
  payoutMethod: string;
  reportDate: string;
  balancePlatformAccountHolderId?: string;
};

export type PayoutDetailItem = {
  bookingDate: string;
  timeZoneCode: string;
  recordType: string;
  pspReference: string;
  amount: string;
  location: string;
  rollingBalance: string;
  transactionCreatedDate: string;
  paymentOwnerName: string;
  accountHolderCode: string;
};

export type GetDepositDetailReportResponse = {
  details: PayoutDetailItem[];
  payoutAmount: string;
  rollingBalance: string;
};

export type GetPayoutDetailReportNoContentResponse =
  GetDepositDetailReportResponse & {
    noContent: boolean;
  };

export async function getTTMData() {
  return await httpCall<GetTTMDataResponse>("GET", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/GetTTMData`,
    errorMessage: `Unable to get TTM Data, please try again.`,
  });
}

export async function getDepositSummaryReport(body: DepositSummaryReportBody) {
  return await httpCall<GetDepositSummaryReportResponse, unknown>("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/DepositSummaryReport`,
    errorMessage: `Unable to get deposit summary report for account holder ${body.accountHolderCode} and date ${body.reportDate}. Please try again.`,
    body,
  });
}

export async function getDepositDetailReport(body: DepositDetailReportBody) {
  return await httpCall<GetDepositDetailReportResponse, unknown>("POST", {
    url: `${process.env.REACT_APP_PAYMENTS_API_URI}/DepositDetailReport`,
    errorMessage: `Unable to get deposit summary report for account holder ${body.accountHolderCode} and date ${body.reportDate}. Please try again.`,
    body,
  });
}

export function createEmptyPayoutDetailItem(): PayoutDetailItem {
  return {
    bookingDate: "",
    timeZoneCode: "",
    recordType: "",
    pspReference: "",
    amount: "",
    location: "",
    rollingBalance: "",
    transactionCreatedDate: "",
    paymentOwnerName: "",
    accountHolderCode: "",
  };
}

// Create a default ReportTransaction with all properties set to empty strings
export function createEmptyReportTransaction(): ReportTransaction {
  return {
    pspReference: "",
    accountHolderCode: "",
    business: "",
    location: "",
    merchantReference: "",
    merchantAccount: "",
    transactionCreatedDate: "",
    timeZoneCode: "",
    paymentOwnerName: "",
    paymentTypeNumber: "",
    paymentMethod: "",
    paymentLifeCycleStatus: "",
    fundingSource: "",
    amountCurrency: "",
    creditCardProcessingFeeAmount: "",
    technologyFee: "",
    amountValue: "",
    transactionType: "",
    transactionFeeAmount: "",
    businessNet: "",
    originalChargeAmount: "",
    convenienceFeeAmount: "",
    refundAmount: "",
    processingFees: "",
    chargeBackAmount: "",
    payoutReportDate: "",
  };
}
