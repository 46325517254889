import { RatePlan } from "./RatePlans";
import { AccountHolderRatePlan } from "../../services/accountService";
import RatePlanList from "./RatePlanList";
import MonthlyFee from "./MonthlyFee";

type Props = {
  accountHolderCode: string;
  accountHolderId: string;
  accountHolderRatePlans: AccountHolderRatePlan[];
  useInterchangeRate: boolean;
  hasMonthlyFee: boolean;
  monthlyFee: string;
  isLoading: boolean;
};

const ratePlans: RatePlan[] = [{ id: "interchange", label: "Rate" }];

function InterchangePlusPlan({
  accountHolderCode,
  accountHolderId,
  accountHolderRatePlans,
  useInterchangeRate,
  hasMonthlyFee,
  monthlyFee,
  isLoading,
}: Props): JSX.Element {
  return (
    <div className="flex w-full flex-col flex-wrap">
      <RatePlanList
        accountHolderCode={accountHolderCode}
        accountHolderId={accountHolderId}
        accountHolderRatePlans={accountHolderRatePlans}
        ratePlans={ratePlans}
        isLoading={isLoading}
      />
      <MonthlyFee
        accountHolderCode={accountHolderCode}
        accountHolderId={accountHolderId}
        hasMonthlyFee={hasMonthlyFee}
        monthlyFee={monthlyFee}
      />
    </div>
  );
}

export default InterchangePlusPlan;
