import Input from "../../components/Input";
import { formatAmount } from "../../utils/currency";
import EditControls from "./EditControls";
import { RatePlan } from "./RatePlans";

type RatePlanRowProps = {
  ratePlan: RatePlan;
  index: number;
  isEditing: boolean;
  currentEditingRatePlan: RatePlan | null;
  setCurrentEditingRatePlan: (ratePlan: RatePlan | null) => void;
  addToInputRefs: (e: HTMLInputElement) => void;
  isUpdatingRatePlan: boolean;
  handleEditClick: (ratePlan: RatePlan, index: number) => void;
  handleUpdateRatePlan: () => void;
  currentEditingSaveButtonEnabled: boolean;
};

function RatePlanRow({
  ratePlan,
  index,
  isEditing,
  currentEditingRatePlan,
  setCurrentEditingRatePlan,
  addToInputRefs,
  isUpdatingRatePlan,
  handleEditClick,
  handleUpdateRatePlan,
  currentEditingSaveButtonEnabled,
}: RatePlanRowProps) {
  return (
    <div className="grid grid-cols-[1fr_1.2fr_1.2fr_1fr] items-center gap-x-1">
      <span className="font-medium capitalize">{ratePlan.label}</span>
      <Input
        id="creditCardRatePlanPercentage"
        type="number"
        ref={addToInputRefs}
        value={
          isEditing
            ? currentEditingRatePlan?.data?.percentageValue
            : formatAmount(ratePlan.data?.percentageValue)
        }
        onChange={(e) =>
          setCurrentEditingRatePlan({
            ...currentEditingRatePlan!,
            data: {
              ...currentEditingRatePlan?.data!,
              percentageValue: e.target.value,
            },
          })
        }
        label="Percentage"
        disabled={!isEditing}
        containerProps={{
          className: "w-28 lg:w-20 xl:w-28",
        }}
      />
      <Input
        id="creditCardRatePlanFixedAmount"
        type="number"
        value={
          isEditing
            ? currentEditingRatePlan?.data?.fixedAmount
            : formatAmount(ratePlan.data?.fixedAmount)
        }
        onChange={(e) =>
          setCurrentEditingRatePlan({
            ...currentEditingRatePlan!,
            data: {
              ...currentEditingRatePlan?.data!,
              fixedAmount: e.target.value,
            },
          })
        }
        label="Fixed Amount"
        disabled={!isEditing}
        containerProps={{
          className: "w-28",
        }}
      />
      <div className="flex items-center justify-center gap-x-2">
        <EditControls
          isEditing={isEditing}
          isSaving={isUpdatingRatePlan}
          editButtonProps={{
            variant: "secondary",
            className: "w-full",
            onClick: () => handleEditClick(ratePlan, index),
          }}
          saveButtonProps={{
            disabled: !currentEditingSaveButtonEnabled,
            onClick: () => handleUpdateRatePlan(),
          }}
          cancelButtonProps={{
            onClick: () => setCurrentEditingRatePlan(null),
          }}
        />
      </div>
    </div>
  );
}

export default RatePlanRow;
