import { DateTime } from "luxon";
import {
  DateFilterType,
  PayoutDetailItem,
  ReportTransaction,
  Transaction,
  TransactionEvent,
  TransactionLedgerItem,
  TransactionType,
} from "../services/transactionsService";
import {
  formatISODateToLocal,
  getDateTimeFromDateFilterType,
  longDateFormat,
  shortDateFormat,
  urlDateFormat,
  reportDateFormat,
} from "./dateTime";
import { sortISODate } from "./sorting";
import paymentMethodVariants from "../data/paymentMethodVariants.json";
import { formatAmount } from "./currency";
import { capitalize } from "./string";
import { downloadCsvFile, generateCsvData } from "./csv";
import { AmountFilter, DateFilter, TransactionsFilter } from "../atoms";
import { SortingState } from "@tanstack/react-table";

export type FilterViewType = "transactions" | "exceptions";

export function getDefaultTransactionsFilter(
  type: DateFilterType
): TransactionsFilter {
  return {
    amount: {
      from: null,
      to: null,
    },
    cardOrAccountNumber: "",
    pspReference: "",
    merchantReference: "",
    date: {
      type,
      from: getDateTimeFromDateFilterType(type),
      to: DateTime.now(),
    },
    status: [],
    transactionType: null,
    accountHolderCode: "",
    pageNumber: 1,
    pageSize: 20,
    sort: [{ id: "TransactionCreatedDate", desc: true }],
    hideZeroUsdTransactions: true,
  };
}

export function getTransactionsPageFilteredByAccountHolderUrl(
  accountHolderCode: string
) {
  return (
    "/transactions#" +
    getFiltersSearchParams({
      ...getDefaultTransactionsFilter("Last30Days"),
      accountHolderCode,
    })
  );
}

export function getEmptyTransaction(): Transaction {
  return {
    accountCode: "",
    accountHolderCode: "",
    amountCurrency: "",
    amountValue: "",
    amountValueRaw: 0,
    authCode: "",
    business: "",
    id: "",
    location: "",
    lockReason: null,
    merchantAccount: "",
    merchantReference: "",
    originalReference: "",
    paymentDetails: null,
    paymentLifeCycleStatus: "",
    paymentLifeCycleStatusDate: "",
    paymentMethod: "",
    paymentOwnerName: "",
    paymentTypeNumber: "",
    pspReference: "",
    transactionCreatedDate: "",
    paymentLifeCycles: [],
    paymentNotes: [],
    paymentEvents: [],
    refundAmount: "",
    refundAmountRaw: 0,
    accountHolderTimeZone: {
      code: "",
      country: "",
      name: "",
    },
  };
}

export function getEmptyTransactionEvent(): TransactionEvent {
  return {
    accountCode: "",
    accountHolderCode: "",
    accountNumber: "",
    amountCurrency: "",
    amountValue: "",
    amountValueRaw: 0,
    cardHolderName: "",
    cardSummary: "",
    consumerName: "",
    eventType: "",
    exceptionAmountCurrency: "",
    exceptionAmountValue: "",
    exceptionEventStatus: "",
    id: "",
    merchantAccount: "",
    merchantReference: "",
    originalReference: "",
    paymentMethod: "",
    pspReference: "",
    reason: "",
    transactionCreatedBy: "",
    transactionCreatedDate: "",
    createdBy: "",
    updatedBy: "",
    success: false,
  };
}

export function getEmptyTransactionLedgerItem(): TransactionLedgerItem {
  return {
    pspReference: "",
    originalReference: "",
    accountHolderCode: "",
    merchantReference: "",
    merchantAccount: "",
    transactionCreatedDate: "",
    transactionOwnerName: "",
    transactionOwnerNumber: "",
    amountCurrency: "",
    amountValue: "",
    eventType: "",
    paymentLifeCycleStatus: "",
    business: "",
    location: "",
    paymentMethod: "",
    timeZoneCode: "",
  };
}

export function getTransactionStatusColor(
  status: string,
  type: FilterViewType
) {
  const green = "bg-success";
  const gray = "bg-gray-400";
  const red = "bg-error";

  switch (status) {
    case "Authorised":
    case "Settled":
    case "SettleScheduled":
    case "Settle Scheduled":
      return green;
    case "Cancelled":
    case "SentForSettle":
    case "Sent For Settle":
    case "SentForRefund":
    case "Sent For Refund":
    case "RefundScheduled":
    case "Refund Scheduled":
    case "Refunded":
      return gray;
    case "Refused":
    case "Error":
    case "CaptureFailed":
    case "Capture Failed":
    case "RefundFailed":
    case "Refund Failed":
    case "ChargebackFailed":
    case "Chargeback Failed":
    case "CancelFailed":
    case "Cancel Failed":
    case "RefundReversed":
    case "Refund Reversed":
    case "NotificationOfChargeback":
    case "Notification of Chargeback":
    case "NotificationOfFraud":
    case "Notification of Fraud":
    case "Fraud":
      return red;
    case "Chargeback":
      return type === "transactions" ? gray : red;
  }
  return "bg-transparent";
}

export function formatEventType(eventType: string): string {
  const formattedEventType = eventType.toLowerCase().replace(/_/g, " ");
  return capitalize(formattedEventType);
}

export function formatTransactionEvent(transactionEvent: TransactionEvent) {
  return {
    ...transactionEvent,
    transactionCreatedDate: formatISODateToLocal(
      transactionEvent.transactionCreatedDate,
      longDateFormat
    ),
    amountValue: formatAmount(
      transactionEvent.eventType === "REFUND"
        ? Number(transactionEvent.amountValue) * -1
        : Number(transactionEvent.amountValue)
    ),
    amountValueRaw:
      transactionEvent.eventType === "REFUND"
        ? Number(transactionEvent.amountValue) * -1
        : Number(transactionEvent.amountValue),
    exceptionAmountValue: transactionEvent.exceptionAmountValue
      ? formatAmount(+transactionEvent.exceptionAmountValue)
      : "",
    eventType: formatEventType(transactionEvent.eventType),
  };
}

export function formatTransaction(transaction: Transaction): Transaction {
  return {
    ...transaction,
    paymentTypeNumber:
      transaction.paymentMethod === "ach"
        ? transaction.paymentTypeNumber.slice(-3)
        : transaction.paymentTypeNumber.slice(-4),
    amountValue: formatAmount(Number(transaction.amountValue)),
    amountValueRaw: Number(transaction.amountValue),
    refundAmount: formatAmount(Number(transaction.refundAmount)),
    refundAmountRaw: Number(transaction.refundAmount),
    transactionCreatedDate: formatISODateToLocal(
      transaction.transactionCreatedDate,
      longDateFormat
    ),
    paymentDetails: transaction.paymentDetails
      ? {
          ...transaction.paymentDetails,
          expiryDate: DateTime.fromISO(
            transaction.paymentDetails?.expiryDate
          ).toFormat(shortDateFormat),
          cardNumber:
            transaction.paymentDetails?.cardType === "ach"
              ? transaction.paymentDetails?.bankAccountNumber?.slice(-3)
              : transaction.paymentDetails?.cardNumber?.slice(-4),
          technologyFeeRaw: Number(transaction.paymentDetails.technologyFee),
          technologyFee: formatAmount(
            Number(transaction.paymentDetails?.technologyFee)
          ),
          convenienceFeeAmount: formatAmount(
            Number(transaction.paymentDetails?.convenienceFeeAmount)
          ),
          creditCardProcessingFeeAmount: formatAmount(
            Number(transaction.paymentDetails?.creditCardProcessingFeeAmount)
          ),
          transactionFeeAmount: formatAmount(
            Number(transaction.paymentDetails?.transactionFeeAmount)
          ),
          commissionAmount: formatAmount(
            Number(transaction.paymentDetails?.commissionAmount)
          ),
          splitMarketPlace: formatAmount(
            Number(transaction.paymentDetails?.splitMarketPlace)
          ),
        }
      : null,
    paymentLifeCycles: transaction.paymentLifeCycles
      ? transaction.paymentLifeCycles
          .map((l) => ({
            ...l,
            journalCreatedDate: formatISODateToLocal(
              l.journalCreatedDate,
              longDateFormat
            ),
            utcTime: formatISODateToLocal(l.utcTime, longDateFormat),
            mainAmount: formatAmount(Number(l.mainAmount)),
            mainAmountRaw: Number(l.mainAmount),
          }))
          .reverse()
      : [],
    paymentEvents: transaction.paymentEvents
      ? transaction.paymentEvents
          .sort((a, b) =>
            sortISODate(
              a.transactionCreatedDate,
              b.transactionCreatedDate,
              "desc"
            )
          )
          .map(formatTransactionEvent)
      : [],
    paymentNotes: transaction.paymentNotes
      ?.sort((a, b) => sortISODate(a.dateCreated, b.dateCreated, "desc"))
      .map((n) => ({
        ...n,
        dateCreated: formatISODateToLocal(n.dateCreated, longDateFormat),
      })),
  };
}

export function getPaymentVariantFullLabel(paymentMethodValue: string) {
  return paymentMethodVariants.find((p) => p.value === paymentMethodValue)
    ?.label;
}

export function downloadTransactionsCsv(transactions: Transaction[]) {
  const csvData = generateCsvData<Transaction>(transactions, [
    { label: "PSP Reference", value: "pspReference" },
    { label: "Account Holder Code", value: "accountHolderCode" },
    { label: "Business", value: "business" },
    { label: "Location", value: "location" },
    { label: "Merchant Reference", value: "merchantReference" },
    { label: "Merchant Account", value: "merchantAccount" },
    { label: "Transaction Created Date", value: "transactionCreatedDate" },
    { label: "Payment Owner Name", value: "paymentOwnerName" },
    { label: "Payment Type Number", value: "paymentTypeNumber" },
    { label: "Payment Method", value: "paymentMethod" },
    { label: "Payment Lifecycle Status", value: "paymentLifeCycleStatus" },
    { label: "Payment Currency", value: "amountCurrency" },
    { label: "Business", value: "paymentDetails.splitMarketPlace" },
    { label: "Processing Fee", value: "paymentDetails.commissionAmount" },
    { label: "Technology Fee", value: "paymentDetails.technologyFee" },
    { label: "Total", value: "amountValue" },
  ]);
  downloadCsvFile(csvData, "transactions.csv");
}

export function downloadExceptionsCsv(exceptions: TransactionEvent[]) {
  const csvData = generateCsvData<TransactionEvent>(exceptions, [
    { label: "PSP Reference", value: "pspReference" },
    { label: "Merchant Reference", value: "merchantReference" },
    { label: "Merchant Account", value: "merchantAccount" },
    { label: "Transaction Created Date", value: "transactionCreatedDate" },
    { label: "Card Holder Name", value: "cardHolderName" },
    { label: "Account Number", value: "accountNumber" },
    { label: "Payment Method", value: "paymentMethod" },
    { label: "Amount Value", value: "amountValue" },
    { label: "Exception Amount Currency", value: "exceptionAmountCurrency" },
    { label: "Exception Amount Value", value: "exceptionAmountValue" },
    { label: "Exception Event Status", value: "exceptionEventStatus" },
  ]);
  downloadCsvFile(csvData, "exceptions.csv");
}

export function downloadReportCsv(
  transactions: ReportTransaction[],
  reportName: string
) {
  const csvData = generateCsvData<ReportTransaction>(transactions, [
    { label: "PSP Reference", value: "pspReference" },
    { label: "Account Holder Code", value: "accountHolderCode" },
    { label: "Business", value: "business" },
    { label: "Location", value: "location" },
    { label: "Merchant Reference", value: "merchantReference" },
    { label: "Transaction Created Date", value: "transactionCreatedDate" },
    { label: "Time Zone", value: "timeZoneCode" },
    { label: "Payment Owner Name", value: "paymentOwnerName" },
    { label: "Payment Type Number", value: "paymentTypeNumber" },
    { label: "Payment Method", value: "paymentMethod" },
    { label: "Transaction Type", value: "transactionType" },
    { label: "Funding Source", value: "fundingSource" },
    { label: "Payment Lifecycle Status", value: "paymentLifeCycleStatus" },
    { label: "Payment Currency", value: "amountCurrency" },
    { label: "Sale", value: "originalChargeAmount" },
    { label: "(+) Surcharge", value: "convenienceFeeAmount" },
    { label: "(+) Technology", value: "technologyFee" },
    { label: "(-) Refunds", value: "refundAmount" },
    { label: "(=) Net", value: "amountValue" },
    { label: "(-) Processing", value: "processingFees" }, // Includes CreditCardProcessingFeeAmount and TransactionFeeAmount
    { label: "(-) Technology", value: "technologyFee" },
    { label: "(-) Chargeback", value: "chargeBackAmount" },
    { label: "(=) Net Sales", value: "businessNet" },
    { label: "Payout Initiated (Net)", value: "payoutReportDate" },
  ]);
  downloadCsvFile(csvData, reportName);
}

export function downloadTransactionLedgerCsv(
  transactions: TransactionLedgerItem[],
  reportName: string
) {
  const csvData = generateCsvData<TransactionLedgerItem>(transactions, [
    { label: "PSP Reference", value: "pspReference" },
    { label: "Transaction Type", value: "eventType" },
    { label: "Status", value: "paymentLifeCycleStatus" },
    { label: "Date", value: "transactionCreatedDate" },
    { label: "Timezone", value: "timeZoneCode" },
    { label: "Amount", value: "amountValue" },
    { label: "Payment Type", value: "paymentMethod" },
    { label: "Account Holder Code", value: "accountHolderCode" }, //only for CSR
    { label: "Location", value: "location" },
    { label: "Merchant Account", value: "merchantAccount" }, //only for CSR
    { label: "Cardholder/AcccountHolder Name", value: "transactionOwnerName" },
    { label: "Card/Account Number", value: "transactionOwnerNumber" },
  ]);
  downloadCsvFile(csvData, reportName);
}

export function downloadPayoutDetailCsv(
  items: PayoutDetailItem[],
  reportName: string
) {
  const csvData = generateCsvData<PayoutDetailItem>(items, [
    { label: "PSP Reference", value: "pspReference" },
    { label: "Account Holder Code", value: "accountHolderCode" },
    { label: "Business", value: "business" },
    { label: "Location", value: "location" },
    { label: "Payment Owner Name", value: "paymentOwnerName" },
    { label: "Type", value: "recordType" },
    { label: "Amount", value: "amount" },
    // Removing this for now. We may add this back in when we go to the balance platform
    // { label: "Payout Initiated", value: "date" },
  ]);
  downloadCsvFile(csvData, reportName);
}

function getFiltersSearchParams(filter: TransactionsFilter) {
  return Object.entries(filter).reduce((acc, [key, value], i) => {
    let formattedValue: string;
    switch (key) {
      case "amount":
        const { from: fromAmount, to: toAmount } = value as AmountFilter;
        formattedValue = `${fromAmount || null},${toAmount || null}`;
        break;
      case "transactionType":
        const transactionType = value as TransactionType | null;
        formattedValue = transactionType || "";
        break;
      case "date":
        const { type, from, to } = value as DateFilter;

        formattedValue =
          type === "Custom" && from && to
            ? `${type},${from.toFormat(urlDateFormat)},${to.toFormat(
                urlDateFormat
              )}`
            : `${type},,`;
        break;
      case "sort":
        const defaultSort = value as SortingState;
        formattedValue = `${defaultSort.at(0)?.id}:${
          defaultSort.at(0)?.desc ? "desc" : "asc"
        }`;
        break;
      default:
        formattedValue = value as string;
    }
    return (
      acc + `${i > 0 ? "&" : ""}${key}=${encodeURIComponent(formattedValue)}`
    );
  }, "");
}

export function getTransactionsPageDefaultUrl() {
  return (
    "transactions#" +
    getFiltersSearchParams(getDefaultTransactionsFilter("Last1Hour"))
  );
}

export function formatReportTransaction(
  transaction: ReportTransaction
): ReportTransaction {
  return {
    ...transaction,
    transactionCreatedDate: formatISODateToLocal(
      transaction.transactionCreatedDate,
      longDateFormat
    ),
    creditCardProcessingFeeAmount: formatAmount(
      transaction.creditCardProcessingFeeAmount
    ).replaceAll(",", ""),
    technologyFee: formatAmount(transaction.technologyFee).replaceAll(",", ""),
    amountValue: formatAmount(transaction.amountValue).replaceAll(",", ""),
    businessNet: formatAmount(transaction.businessNet).replaceAll(",", ""),
    transactionFeeAmount: formatAmount(
      transaction.transactionFeeAmount
    ).replaceAll(",", ""),
    payoutReportDate: formatISODateToLocal(
      transaction.payoutReportDate,
      reportDateFormat
    ),
  };
}
