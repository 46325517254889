import DetailField from "../../components/DetailField";
import {
  Transaction,
  TransactionDetail,
} from "../../services/transactionsService";
import Panel from "../customerSettings/Panel";

export type Props = {
  transaction?: Transaction | null;
  transactionDetail?: TransactionDetail | null;
  isLoading?: boolean;
};

function TransactionPaymentFee({
  transaction,
  transactionDetail,
  isLoading = false,
}: Props): JSX.Element {
  return (
    <Panel className="my-2 w-full rounded-lg border border-gray-200 shadow-sm md:w-[49%]">
      <Panel.Header title="Payment Fees" className="rounded-b-none" />
      <Panel.Content>
        <div className="flex flex-col gap-x-4 gap-y-4 p-4 md:p-2">
          <DetailField label="Type" isLoading={isLoading} variant="start">
            Technology Fees
          </DetailField>
          <DetailField label="Amount" isLoading={isLoading} variant="start">
            {transaction?.amountCurrency} {transactionDetail?.technologyFee}
          </DetailField>
          <hr />
          <DetailField label="Type" isLoading={isLoading} variant="start">
            Convenience Fees
          </DetailField>
          <DetailField label="Amount" isLoading={isLoading} variant="start">
            {transaction?.amountCurrency}{" "}
            {transactionDetail?.convenienceFeeAmount}
          </DetailField>
          <hr />
          <DetailField label="Type" isLoading={isLoading} variant="start">
            {transaction?.paymentMethod === "ach"
              ? "ACH Processing Fees"
              : "Credit Card Processing Fees"}
          </DetailField>
          <DetailField label="Amount" isLoading={isLoading} variant="start">
            {transaction?.amountCurrency}{" "}
            {transactionDetail?.creditCardProcessingFeeAmount}
          </DetailField>
          <hr />
          <DetailField label="Type" isLoading={isLoading} variant="start">
            Transaction Fees
          </DetailField>
          <DetailField label="Amount" isLoading={isLoading} variant="start">
            {transaction?.amountCurrency}{" "}
            {transactionDetail?.transactionFeeAmount}
          </DetailField>
        </div>
      </Panel.Content>
    </Panel>
  );
}

export default TransactionPaymentFee;
