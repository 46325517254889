import React from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  label?: string;
};

const Checkbox = React.forwardRef<
  HTMLInputElement,
  Props & React.InputHTMLAttributes<HTMLInputElement>
>(({ label = "", ...inputProps }, ref): JSX.Element => {
  return (
    <div className="flex items-center gap-x-4">
      <input
        ref={ref}
        {...inputProps}
        type="checkbox"
        className={twMerge(
          "rounded text-primary-700 focus:ring-primary-700",
          inputProps.disabled && "cursor-not-allowed",
          inputProps.className
        )}
      />
      {label && (
        <label htmlFor={inputProps.id} className="text-sm">
          {label}
        </label>
      )}
    </div>
  );
});

export default Checkbox;
