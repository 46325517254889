import Button from "../../components/Button";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import { useSnackbar } from "../../components/Snackbar";
import { Transaction } from "../../services/transactionsService";
import useRefundTransactionMutation from "../../hooks/data/mutations/useRefundTransactionMutation";
import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Spinner from "../../components/Spinner";
import Tab from "../../components/Tab";
import { formatAmount } from "../../utils/currency";

type Props = {
  isOpen: boolean;
  transaction: Transaction;
  onClose: () => void;
};

const refundTypes = ["Full", "Partial"];

function RefundTransactionModal({
  isOpen,
  transaction,
  onClose,
}: Props): JSX.Element {
  const [tabIndex, setTabIndex] = useState(-1);
  const [amountToRefund, setAmountToRefund] = useState<string>(
    calculateAmountToRefund()
  );
  const [error, setError] = useState<string>("");
  const { mutate: refund, isLoading } = useRefundTransactionMutation(
    transaction.pspReference
  );
  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
    classes: "top-24",
  });

  const hasRefunds = transaction.refundAmountRaw > 0;

  useEffect(() => {
    if (hasRefunds) {
      setTabIndex(1);
    }
  }, [hasRefunds]);

  useEffect(() => {
    setAmountToRefund(calculateAmountToRefund());
  }, [tabIndex]);

  function calculateAmountToRefund() {
    if (tabIndex === 0) {
      return formatAmount(transaction.amountValueRaw);
    }
    return formatAmount(
      transaction.amountValueRaw -
        transaction.refundAmountRaw -
        (transaction?.paymentDetails?.technologyFeeRaw ?? 0)
    );
  }

  function handleSumbitRefundClick() {
    refund(
      {
        currency: transaction.amountCurrency,
        pspReference: transaction.pspReference,
        amount: +(Number(amountToRefund.replace(",", "")) * 100).toFixed(2),
        merchantAccount: transaction.merchantAccount,
      },
      {
        onSuccess() {
          openSnackbar("Request to refund the payment has been received.", {
            type: "success",
          });
        },
        onError() {
          openSnackbar("An error has occurred. Please try again.", {
            type: "error",
          });
        },
        onSettled() {
          setAmountToRefund("");
          setError("");
          onClose();
        },
      }
    );
  }

  function handleSubmitCancelClick() {
    setAmountToRefund(calculateAmountToRefund());
    setError("");
    onClose();
  }

  function handleAmountToRefundChange(e: React.ChangeEvent<HTMLInputElement>) {
    const updatedAmountToRefund = e.target.value;
    setAmountToRefund(updatedAmountToRefund);

    const refundedAmount = transaction.refundAmountRaw;
    const authorisedAmount = transaction.amountValueRaw;
    const techFee = transaction.paymentDetails?.technologyFeeRaw ?? 0;

    if (
      Number(updatedAmountToRefund) + techFee + refundedAmount >
      authorisedAmount
    ) {
      setError(
        refundedAmount
          ? "Already partially refunded, new requested refund amount too high."
          : "Requested refund amount too high."
      );
    } else {
      setError("");
    }
  }

  function handleTabChange(index: number) {
    if (!hasRefunds) {
      setTabIndex(index);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Refund payment"
      showCloseButton={false}
      panelClasses="sm:w-80"
      onClose={() => onClose()}
    >
      <div className="mt-2 flex flex-col items-center gap-y-6">
        <Tab.Group selectedIndex={tabIndex} onChange={handleTabChange}>
          <Tab.List>
            {refundTypes.map((type, i) => (
              <Tab index={i} disabled={hasRefunds} isLoading={isLoading}>
                {type}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {refundTypes.map((type, i) => (
              <div className="mt-2 flex h-[248px] flex-col gap-y-4">
                <Input
                  defaultValue={transaction.amountValue}
                  label="Authorised Amount"
                  adornmentText={transaction.amountCurrency}
                  disabled
                  containerProps={{
                    className: "w-full my-2",
                  }}
                />
                {tabIndex === 1 && (
                  <>
                    <Input
                      defaultValue={transaction.paymentDetails?.technologyFee}
                      label="Tech Amount"
                      adornmentText={transaction.amountCurrency}
                      disabled
                      containerProps={{
                        className: "w-full my-2",
                      }}
                    />
                    <Input
                      defaultValue={transaction.refundAmount}
                      label="Refunded Amount"
                      adornmentText={transaction.amountCurrency}
                      disabled
                      containerProps={{
                        className: "w-full my-2",
                      }}
                    />
                  </>
                )}
                <Input
                  value={amountToRefund}
                  label="Amount"
                  onChange={handleAmountToRefundChange}
                  containerProps={{
                    className: "w-full my-2",
                  }}
                  adornmentText={transaction.amountCurrency}
                  disabled={tabIndex === 0}
                />
              </div>
            ))}
          </Tab.Panels>
        </Tab.Group>
        <Transition
          show={Boolean(error)}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {error && <span className="text-error">{error}</span>}
        </Transition>
        <div className="flex w-full justify-between">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleSubmitCancelClick}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            disabled={isLoading || error !== "" || amountToRefund === ""}
            onClick={handleSumbitRefundClick}
            className="flex"
            icon={isLoading && <Spinner className="mt-0.5 mr-1 text-white" />}
          >
            Refund
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default RefundTransactionModal;
