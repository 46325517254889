import { createColumnHelper } from "@tanstack/react-table";
import { DateTime } from "luxon";
import TableHeader from "../../components/TableHeader";
import { AccountHolderNote } from "../../services/accountService";
import { longDateFormat } from "../../utils/dateTime";
import { sortDateTimes } from "../../utils/sorting";
import AddNoteCell from "../transaction/Notes/AddNoteCell";
import { UserNotesTableMeta } from "./UserNotes";

const columnHelper = createColumnHelper<AccountHolderNote>();
export const getUserNotesTableColumns = () => [
  columnHelper.accessor("userEmail", {
    header: () => <TableHeader text="User" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 100,
    size: 300,
  }),
  columnHelper.accessor("dateCreated", {
    header: () => <TableHeader text="Date" />,
    cell: (info) => {
      return <div className="truncate">{info.getValue()}</div>;
    },
    minSize: 100,
    size: 200,
    sortingFn: (rowA, rowB, columnId) => {
      const rowADate = DateTime.fromFormat(
        rowA.getValue(columnId),
        longDateFormat
      );
      const rowBDate = DateTime.fromFormat(
        rowB.getValue(columnId),
        longDateFormat
      );

      return sortDateTimes(rowADate, rowBDate);
    },
  }),
  columnHelper.accessor("note", {
    header: () => <TableHeader text="Note" />,
    cell: (info) => {
      const userEmail = info.row.getValue("userEmail");
      const dateCreated = info.row.getValue("dateCreated");
      const text = info.getValue();
      const { onNoteSaved, onNoteCanceled, isSavingNote } = info.table.options
        .meta as UserNotesTableMeta;

      if (!userEmail && !dateCreated && !text) {
        return (
          <AddNoteCell
            onNoteSaved={onNoteSaved}
            onNoteCanceled={onNoteCanceled}
            isSavingNote={isSavingNote}
          />
        );
      }

      return <div className="truncate">{text}</div>;
    },
    minSize: 100,
    size: 700,
  }),
];
