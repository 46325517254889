import { useQueryClient, useMutation } from "@tanstack/react-query";
import {
  UpdateMerchantConfigBody,
  updateMerchantConfig,
  AccountHolder,
  GetTimeZonesResponse,
} from "../../../services/accountService";
import { formatAmount } from "../../../utils/currency";
import useCreateAccountHolderNotesMutation from "./useCreateAccountHolderNotesMutation";
import convenienceFeeRates from "../../../data/convenienceFeeRates.json";

export default function useUpdateMerchantConfigMutation(
  accountHolderCode: string
) {
  const queryClient = useQueryClient();
  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  return useMutation<string, Error, UpdateMerchantConfigBody>(
    (body) => updateMerchantConfig(body),
    {
      async onSuccess(_, updatedMerchantConfig) {
        const accountConfigData = queryClient.getQueryData<AccountHolder>([
          "accountConfig",
          accountHolderCode,
        ]);

        if (!accountConfigData) return;

        queryClient.setQueryData(["accountConfig", accountHolderCode], {
          ...accountConfigData,
          ...updatedMerchantConfig,
        });

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "merchantStatus",
          accountConfigData.merchantStatus,
          updatedMerchantConfig.merchantStatus
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "adyenCaptureDelay",
          String(accountConfigData.adyenCaptureDelay),
          String(updatedMerchantConfig.adyenCaptureDelay)
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "shopperStatement",
          accountConfigData.shopperStatement,
          updatedMerchantConfig.shopperStatement
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "Use Transaction Limit",
          accountConfigData.useTransAmountLimit.toString(),
          updatedMerchantConfig.useTransAmountLimit.toString()
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "Transaction Limit",
          formatAmount(+accountConfigData.transAmountLimit),
          formatAmount(+updatedMerchantConfig.transAmountLimit)
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "Use Daily Processing Limit",
          accountConfigData.useDailyTransAmountLimit.toString(),
          updatedMerchantConfig.useDailyTransAmountLimit.toString()
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "Daily Processing Limit",
          formatAmount(+accountConfigData.dailyTransAmountLimit),
          formatAmount(+updatedMerchantConfig.dailyTransAmountLimit)
        );

        const timezonesData = queryClient.getQueryData<GetTimeZonesResponse>([
          "timezones",
          accountConfigData.country,
        ]);

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "timeZone",
          accountConfigData.timeZone.name,
          timezonesData?.find(
            (x) => x.code === updatedMerchantConfig.timeZoneCode
          )?.name ?? ""
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "Tech Fee Rate",
          accountConfigData.useTechFee ? "Disabled" : "Enabled",
          updatedMerchantConfig.useTechFee ? "Disabled" : "Enabled"
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "Tech Fee Rate Percentage",
          accountConfigData.techFeeRate,
          formatAmount(+updatedMerchantConfig.techFeeRate)
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "Surcharge Rate",
          accountConfigData.useConvFee ? "Disabled" : "Enabled",
          updatedMerchantConfig.useConvFee ? "Disabled" : "Enabled"
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "Surcharge Rate Plan",
          accountConfigData.convFee === "M" ? "Match Rate Plan" : "Fixed Rate",
          updatedMerchantConfig.convFee === "M"
            ? "Match Rate Plan"
            : "Fixed Rate"
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "Surcharge Rate Percentage",
          accountConfigData.convFeeRate,
          formatAmount(+updatedMerchantConfig.convFeeRate)
        );

        await createNoteIfFromAndToAreDifferent(
          accountHolderCode,
          "inBalancePlatform",
          accountConfigData.inBalancePlatform ? "Balance" : "Classic",
          updatedMerchantConfig.inBalancePlatform ? "Balance" : "Classic"
        );

        queryClient.invalidateQueries(["accountConfig", accountHolderCode]);

        async function createNoteIfFromAndToAreDifferent(
          accountHolderCode: string,
          field: string,
          from: string,
          to: string
        ) {
          if (from && to && from !== to) {
            const fromValue =
              field === "convFee" ? findConvenienceFeeLabel(from) : from;
            const toValue =
              field === "convFee" ? findConvenienceFeeLabel(to) : to;
            await createNote({
              accountHolderCode,
              noteContent: [
                {
                  fieldName: field,
                  fromValue: fromValue || "null",
                  toValue: toValue || "null",
                },
              ],
            });
          }
        }
      },
    }
  );
}

function findConvenienceFeeLabel(value: string) {
  return convenienceFeeRates.find((cf) => cf.value === value)?.label ?? "";
}
