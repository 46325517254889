import { useEffect, useState } from "react";
import FlatRatePlan from "./FlatRatePlan";
import InterchangePlusPlan from "./InterchangePlusPlan";
import { AccountHolderRatePlan } from "../../services/accountService";
import useUpdateInterchangeFee from "../../hooks/data/mutations/useUpdateInterchangeFee";
import { useSnackbar } from "../../components/Snackbar";
import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import Panel from "./Panel";
import Tab from "../../components/Tab";

export type RatePlan = {
  id: string;
  label: string;
  data?: AccountHolderRatePlan;
};

const ratePlanTypes = ["Flat Rate Plan", "Interchange + Plan"];

type Props = {
  accountHolderCode: string;
  accountHolderId: string;
  accountHolderRatePlans: AccountHolderRatePlan[];
  useInterchangeRate: boolean;
  hasMonthlyFee: boolean;
  monthlyFee: string;
  isLoading: boolean;
};

function RatePlans({
  accountHolderCode,
  accountHolderId,
  accountHolderRatePlans,
  useInterchangeRate,
  hasMonthlyFee,
  monthlyFee,
  isLoading,
}: Props): JSX.Element {
  const { data: currentUserAccount } = useCurrentUserAccount();
  const isCSR = currentUserAccount?.type === "CSR";
  const [tabIndex, setTabIndex] = useState(-1);
  const { mutate } = useUpdateInterchangeFee();
  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });

  useEffect(() => {
    setTabIndex(useInterchangeRate ? 1 : 0);
  }, [useInterchangeRate]);

  function handleTabChange(index: number) {
    if (isCSR) {
      setTabIndex(index);
      mutate({
        accountHolderCode,
        useInterchangeRate: index === 1,
      });

      openSnackbar(`${ratePlanTypes[index]} selected.`, {
        type: "success",
        duration: 5000,
      });
    }
  }

  return (
    <Panel className="col-span-4">
      <Panel.Header title="Rate Plans" />
      <Panel.Content>
        <Tab.Group selectedIndex={tabIndex} onChange={handleTabChange}>
          <Tab.List>
            {ratePlanTypes.map((type, i) => (
              <Tab key={i} index={i} disabled={!isCSR} isLoading={isLoading}>
                {type}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <FlatRatePlan
              accountHolderCode={accountHolderCode}
              accountHolderId={accountHolderId}
              accountHolderRatePlans={accountHolderRatePlans}
              useInterchangeRate={useInterchangeRate}
              isLoading={isLoading}
            />
            <InterchangePlusPlan
              accountHolderCode={accountHolderCode}
              accountHolderId={accountHolderId}
              accountHolderRatePlans={accountHolderRatePlans}
              useInterchangeRate={useInterchangeRate}
              isLoading={isLoading}
              hasMonthlyFee={hasMonthlyFee}
              monthlyFee={monthlyFee}
            />
          </Tab.Panels>
        </Tab.Group>
      </Panel.Content>
    </Panel>
  );
}

export default RatePlans;
