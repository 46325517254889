import Skeleton from "../../components/Skeleton";
import { AccountHolder } from "../../services/accountService";
import Panel from "./Panel";
import useMerchantAccountsSysConfigQuery from "../../hooks/data/queries/useMerchantAccountsSysConfigQuery";

type Props = {
  accountConfigData: AccountHolder | undefined;
  isLoading: boolean;
};

function AdyenConfigurations({
  accountConfigData,
  isLoading,
}: Props): JSX.Element {
  const companyId = "JackRabbitTech";
  const { data: merchantAccounts } =
    useMerchantAccountsSysConfigQuery(companyId);

  const inBalance = Boolean(accountConfigData?.inBalancePlatform ?? false);
  const isMigrated = Boolean(accountConfigData?.migrated ?? false);
  const platformMerchantName =
    isMigrated && inBalance
      ? merchantAccounts?.find(
          (id) => id.merchantAccountId === accountConfigData?.merchantAccountId
        )?.balancePlatformId
      : accountConfigData?.adyenMerchantAccount;

  return (
    <Panel className="col-span-7">
      <Panel.Header title="Configurations" />
      <Panel.Content>
        <div className="grid grid-cols-2 grid-rows-6 gap-x-2 gap-y-2">
          <div className="font-medium">Payment Processing Status</div>
          <div className="truncate">
            {isLoading ? (
              <Skeleton />
            ) : accountConfigData?.paymentProcessingState ? (
              "Enabled"
            ) : (
              "Disabled"
            )}
          </div>
          <div className="font-medium">Merchant Account</div>
          <div className="truncate">
            {isLoading ? <Skeleton /> : platformMerchantName}
          </div>

          <div className="font-medium">Payout Status</div>
          <div className="truncate">
            {isLoading ? (
              <Skeleton />
            ) : accountConfigData?.payoutState ? (
              "Enabled"
            ) : (
              "Disabled"
            )}
          </div>

          <div className="font-medium">Account Holder</div>
          <div className="truncate">
            {isLoading ? (
              <Skeleton />
            ) : (
              accountConfigData?.adyenAccountHolderCode
            )}
          </div>

          <div className="font-medium">Entity Type</div>
          <div className="truncate">
            {isLoading ? <Skeleton /> : accountConfigData?.legalEntityType}
          </div>

          <div className="font-medium">Account Holder Status</div>
          <div className="truncate">
            {isLoading ? <Skeleton /> : accountConfigData?.adyenStatus}
          </div>

          <div className="font-medium">Payout Schedule</div>
          <div className="truncate">
            {isLoading ? (
              <Skeleton />
            ) : (
              accountConfigData?.adyenPayoutSchedule ?? "Active"
            )}
          </div>
        </div>
      </Panel.Content>
    </Panel>
  );
}

export default AdyenConfigurations;
