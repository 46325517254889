import { useState } from "react";
import { AccountHolderRatePlan } from "../../services/accountService";
import { RatePlan } from "./RatePlans";
import RatePlanList from "./RatePlanList";

type Props = {
  accountHolderCode: string;
  accountHolderId: string;
  accountHolderRatePlans: AccountHolderRatePlan[];
  useInterchangeRate: boolean;
  isLoading: boolean;
};

const ratePlans: RatePlan[] = [
  { id: "visa", label: "Visa" },
  { id: "mc", label: "Mastercard" },
  { id: "amex", label: "AMEX" },
  { id: "discover", label: "Discover" },
  { id: "ach", label: "ACH" },
  { id: "applepay", label: "Apple Pay" },
  { id: "paypal", label: "Paypal" },
];

const defaultRatePlan: RatePlan[] = [{ id: "scheme", label: "Default rate" }];

function FlatRatePlans({
  accountHolderCode,
  accountHolderId,
  accountHolderRatePlans,
  useInterchangeRate,
  isLoading,
}: Props): JSX.Element {
  return (
    <div className="flex flex-shrink-0 flex-col flex-wrap">
      <div className="mb-3">
        <RatePlanList
          accountHolderCode={accountHolderCode}
          accountHolderId={accountHolderId}
          ratePlans={ratePlans.sort((a, b) => (a.label > b.label ? 1 : -1))}
          accountHolderRatePlans={accountHolderRatePlans}
          isLoading={isLoading}
        />
      </div>
      <div className="mt-5 mb-3 rounded outline outline-offset-[12px] outline-black">
        <RatePlanList
          accountHolderCode={accountHolderCode}
          accountHolderId={accountHolderId}
          ratePlans={defaultRatePlan}
          accountHolderRatePlans={accountHolderRatePlans}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default FlatRatePlans;
