import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import Button from "../../components/Button";
import HasAccess, { csrAdminRoles } from "../../components/HasAccess";
import Skeleton from "../../components/Skeleton";
import { useSnackbar } from "../../components/Snackbar";
import useUpdateAccountMutation from "../../hooks/data/mutations/useUpdateAccountMutation";
import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import { Account } from "../../services/accountService";
import AddNewLocationModal from "../onboarding/AddNewLocationModal";
import EditLocation from "./EditLocation";
import Panel from "./Panel";
import EditControls from "./EditControls";
import useNormalizedBankAccountsQuery from "../../hooks/data/queries/useNormalizedBankAccountsQuery";

type Props = {
  accountHolderCode: string;
  accountHolderId: string;
  locations: Account[];
  isLoading: boolean;
  renderMe: boolean;
};

function Locations({
  accountHolderCode,
  accountHolderId,
  locations,
  isLoading,
  renderMe,
}: Props): JSX.Element {
  const { data: currentUserAccount } = useCurrentUserAccount();
  const { mutate: updateAccount, isLoading: isUpdatingAccount } =
    useUpdateAccountMutation(accountHolderCode);
  const {
    data: bankAccountsData,
    isLoading: isLoadingBankAccounts,
    isFetching: isFetchingBankAccounts,
  } = useNormalizedBankAccountsQuery(accountHolderCode, {
    enabled: currentUserAccount?.type === "CSR",
  });
  const [currentEditingLocation, setCurrentEditingLocation] =
    useState<Account | null>(null);
  const [addNewLocationModalIsOpen, setAddNewLocationModalIsOpen] =
    useState<boolean>(false);
  const queryClient = useQueryClient();

  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });

  function isCurrentLocationBeingEdited(location: Account) {
    return (
      location.adyenAccountCode === currentEditingLocation?.adyenAccountCode
    );
  }

  function handleUpdateAccount() {
    if (currentEditingLocation) {
      updateAccount(
        {
          accountHolderId,
          locationId: currentEditingLocation?.id,
          primaryAccount: currentEditingLocation?.primaryAccount,
          bankUUID: currentEditingLocation?.bankUUID,
          bankAccountLast4: currentEditingLocation.bankAccountLast4,
        },
        {
          onSuccess() {
            openSnackbar(
              `Location ${currentEditingLocation.locationCode} was successfully updated.`,
              {
                type: "success",
              }
            );
            setCurrentEditingLocation(null);
          },
          onError(error) {
            openSnackbar(
              `Failed to update location ${currentEditingLocation.locationCode}. Please try again.`,
              {
                type: "error",
              }
            );
          },
        }
      );
    }
  }

  function handleLocationAdded() {
    queryClient.invalidateQueries(["accountConfig", accountHolderCode]);
  }

  return (
    <Panel className="col-span-4">
      <Panel.Header title="Locations">
        <HasAccess allowedRoles={[...csrAdminRoles]}>
          <Button
            custom-id="addLocationButton"
            size="sm"
            disabled={isLoading || !renderMe}
            onClick={() => setAddNewLocationModalIsOpen(true)}
            icon={<PlusIcon />}
          >
            Add Location
          </Button>
        </HasAccess>
      </Panel.Header>
      <Panel.Content>
        {isLoading || (isLoadingBankAccounts && isFetchingBankAccounts) ? (
          <div className="flex flex-col gap-y-6">
            {Array.from({ length: 3 }).map((_, i) => (
              <div key={i} className="flex gap-x-4">
                <Skeleton className="w-3/4" />
                <Skeleton />
              </div>
            ))}
          </div>
        ) : locations.length > 0 ? (
          <div className="flex flex-col gap-y-2">
            {locations?.map((location, index) => (
              <div key={location.id} className="flex flex-col gap-y-2">
                <div className="flex items-center justify-between gap-x-6">
                  <div className="flex items-center gap-x-2">
                    <span className="font-medium">{location.locationCode}</span>
                    {location.primaryAccount && (
                      <CheckCircleIcon className="h-5 w-5 text-primary-700" />
                    )}
                  </div>
                  <EditControls
                    customId={"editLocationButton" + index}
                    isEditing={isCurrentLocationBeingEdited(location)}
                    isSaving={isUpdatingAccount}
                    editButtonProps={{
                      variant: "secondary",
                      onClick: () => setCurrentEditingLocation(location),
                    }}
                    saveButtonProps={{
                      onClick: handleUpdateAccount,
                      disabled:
                        location.bankUUID ===
                          currentEditingLocation?.bankUUID &&
                        location.primaryAccount ===
                          currentEditingLocation.primaryAccount,
                    }}
                    cancelButtonProps={{
                      onClick: () => setCurrentEditingLocation(null),
                    }}
                  />
                </div>
                <HasAccess allowedRoles={[...csrAdminRoles]}>
                  <EditLocation
                    show={isCurrentLocationBeingEdited(location)}
                    location={currentEditingLocation ?? null}
                    onLocationChange={(location) => {
                      setCurrentEditingLocation(location);
                    }}
                    bankAccountDetails={bankAccountsData || []}
                  />
                </HasAccess>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-4 text-center text-gray-400">
            There are no locations to display.
          </div>
        )}
        <HasAccess allowedRoles={[...csrAdminRoles]}>
          <AddNewLocationModal
            isOpen={addNewLocationModalIsOpen}
            accountHolderCode={accountHolderCode}
            onClose={() => setAddNewLocationModalIsOpen(false)}
            onLocationAdded={handleLocationAdded}
          />
        </HasAccess>
      </Panel.Content>
    </Panel>
  );
}

export default Locations;
