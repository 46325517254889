import { useState } from "react";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { formatAmount } from "../../utils/currency";
import JackrabbitPayLogo from "../../assets/logo-jackrabbit-pay-2023-color-final.svg";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import Info from "../../components/Info";
import ReportTable from "./ReportTable";
import useMonthlyReportQuery from "../../hooks/data/queries/useMonthlyReportQuery";
import ArrowDownTrayIcon from "@heroicons/react/24/solid/ArrowDownTrayIcon";
import { useSnackbar } from "../../components/Snackbar";
import useReportCSVQuery from "../../hooks/data/queries/useReportCSVQuery";
import {
  downloadReportCsv,
  formatReportTransaction,
} from "../../utils/transaction";
import {
  formatISODateToLocal,
  reportDateFormat,
  reportNameDateFormat,
} from "../../utils/dateTime";
import { sortStrings } from "../../utils/sorting";
import useAccountConfigQuery from "../../hooks/data/queries/useAccountConfigQuery";
import {
  ReportTransaction,
  createEmptyReportTransaction,
} from "../../services/transactionsService";

import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import { logCSVInteraction } from "../../utils/appInsightsLogger";

type ReportParams = {
  accountHolderCode: string;
  reportDate: string;
};

function MonthlyReportDetail(): JSX.Element {
  const { data: currentUserAccount } = useCurrentUserAccount();
  const { accountHolderCode, reportDate } = useParams<ReportParams>();
  const [monthlyReportCSVQueryEnabled, setMonthlyReportCSVQueryEnabled] =
    useState(false);
  const { openSnackbar } = useSnackbar({
    duration: 5000,
    position: "top",
  });
  const {
    data: monthlyReport,
    isLoading,
    isError,
    error,
  } = useMonthlyReportQuery({
    accountHolderCode: accountHolderCode || "",
    reportDate: reportDate || "",
  });

  const { isFetching: isFetchingReportCSV } = useReportCSVQuery(
    "monthly",
    {
      accountHolderCode: accountHolderCode || "",
      reportDate: reportDate || "",
    },
    {
      enabled: monthlyReportCSVQueryEnabled,
      onSuccess(data) {
        const reportName = `jackrabbit_pay_monthly_${formatISODateToLocal(
          reportDate || DateTime.now().toString(),
          reportNameDateFormat
        )}.csv`;

        // Format the data to match the csv format
        let items = data.map(formatReportTransaction);

        // Create a summary row
        const reportTransaction: ReportTransaction = {
          ...createEmptyReportTransaction(),
          creditCardProcessingFeeAmount: formatAmount(
            data
              .reduce(
                (sum, x) => sum + Number(x.creditCardProcessingFeeAmount),
                0
              )
              .toFixed(2)
          ).replaceAll(",", ""),
          technologyFee: formatAmount(
            data.reduce((sum, x) => sum + Number(x.technologyFee), 0).toFixed(2)
          ).replaceAll(",", ""),
          amountValue: formatAmount(
            data.reduce((sum, x) => sum + Number(x.amountValue), 0).toFixed(2)
          ).replaceAll(",", ""),
          transactionFeeAmount: formatAmount(
            data
              .reduce((sum, x) => sum + Number(x.transactionFeeAmount), 0)
              .toFixed(2)
          ).replaceAll(",", ""),
          businessNet: formatAmount(
            data.reduce((sum, x) => sum + Number(x.businessNet), 0).toFixed(2)
          ).replaceAll(",", ""),
          originalChargeAmount: formatAmount(
            data
              .reduce((sum, x) => sum + Number(x.originalChargeAmount), 0)
              .toFixed(2)
          ).replaceAll(",", ""),
          convenienceFeeAmount: formatAmount(
            data
              .reduce((sum, x) => sum + Number(x.convenienceFeeAmount), 0)
              .toFixed(2)
          ).replaceAll(",", ""),
          refundAmount: formatAmount(
            data.reduce((sum, x) => sum + Number(x.refundAmount), 0).toFixed(2)
          ).replaceAll(",", ""),
          processingFees: formatAmount(
            data
              .reduce((sum, x) => sum + Number(x.processingFees), 0)
              .toFixed(2)
          ).replaceAll(",", ""),
          chargeBackAmount: formatAmount(
            data
              .reduce((sum, x) => sum + Number(x.chargeBackAmount), 0)
              .toFixed(2)
          ).replaceAll(",", ""),
        };

        // Add the summary row to the items
        items.push(reportTransaction);

        downloadReportCsv(items, reportName);
      },
      onError() {
        openSnackbar(
          "An error has occured while generating the csv file. Please try again.",
          {
            type: "error",
          }
        );
      },
      onSettled() {
        setMonthlyReportCSVQueryEnabled(false);
      },
    }
  );

  const reportDateTime = DateTime.fromISO(reportDate ?? "");
  const lastDayOfMonth = reportDateTime.endOf("month");

  const { data: accountConfigData } = useAccountConfigQuery(accountHolderCode);

  if (isError) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-4">
        <Error message={error} />
        <Button onClick={() => window.close()}>Close this window</Button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-4">
        <Spinner className="!h-12 w-12 text-primary-700" />
        <div className="text-xl">Loading monthly report...</div>
      </div>
    );
  }

  if (monthlyReport.noContent) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-4">
        <Info message={"No data is available."} />
        <Button onClick={() => window.close()}>Close this window</Button>
      </div>
    );
  }

  const processingSummaryData = monthlyReport.methods
    .sort((a, b) => sortStrings(a.type, b.type))
    .map((method) => [
      method.type,
      method.charges,
      formatAmount(method.chargesAmount),
      method.refunds,
      formatAmount(method.refundsAmount),
      method.chargebacks,
      formatAmount(method.chargebacksAmount),
    ]);

  const processingFeesData =
    monthlyReport.processingFeeList?.map((processingFee) => [
      processingFee.feeType,
      processingFee.feeType.includes("Processing Fee")
        ? formatAmount(processingFee.quantity)
        : processingFee.quantity,
      `${formatAmount(processingFee.perTransactionFee)}${
        processingFee.feeType.includes("Processing Fee") ? "%" : ""
      }`,

      formatAmount(processingFee.total),
    ]) ?? [];

  const additionalServiceFeesData =
    monthlyReport.additionalServiceFeeList?.map((additionalFee) => [
      additionalFee.feeType,
      additionalFee.quantity,
      formatAmount(additionalFee.perTransactionFee),
      formatAmount(additionalFee.total),
    ]) ?? [];

  const alignRight = "text-right pr-3";

  return (
    <div className="flex flex-col items-center gap-y-2 py-8 px-4 lg:px-20">
      <img src={JackrabbitPayLogo} alt="Jackrabbit Pay Logo" className="w-96" />
      <h2 className="mb-4 text-2xl font-medium">
        {reportDateTime.toFormat("MM/yyyy")} Processing Report
      </h2>
      <h3 className="mb-4 text-xl">
        {reportDateTime.toFormat(reportDateFormat)} through{" "}
        {lastDayOfMonth.toFormat(reportDateFormat)}
      </h3>
      <h3 className="mb-4 text-xl">Account: {accountHolderCode}</h3>
      <div className="mb-8 flex w-full flex-col gap-y-2">
        <h3 className="my-2 text-2xl">Processing Summary</h3>
        <ReportTable
          columns={[
            { label: "Type" },
            { label: "Charges", className: alignRight },
            { label: "Charges Total", className: alignRight },
            { label: "Refunds", className: alignRight },
            { label: "Refunds Total", className: alignRight },
            { label: "Chargebacks", className: alignRight },
            { label: "Chargebacks Total", className: alignRight },
          ]}
          data={processingSummaryData}
          totalData={[
            "Totals: ",
            monthlyReport.summaryCharges,
            formatAmount(monthlyReport.summaryChargesTotal),
            monthlyReport.summaryRefunds,
            formatAmount(monthlyReport.summaryRefundsTotal),
            monthlyReport.summaryChargeback,
            formatAmount(monthlyReport.summaryChargebackTotal),
          ]}
        />
        <h3 className="my-2 text-2xl">Processing Fees</h3>
        <ReportTable
          columns={[
            { label: "Fee Type" },
            { label: "Quantity", className: alignRight },
            { label: "Per Transaction Fee", className: alignRight },
            { label: "Total", className: alignRight },
          ]}
          data={processingFeesData}
          totalData={[
            "Total Fees PAID:",
            "",
            "",
            formatAmount(monthlyReport.totalFeesPaid),
          ]}
          tableClasses="w-3/4 self-center"
        />
        <div className="mb-3 grid grid-cols-2 gap-x-6 self-center text-xl">
          <div>Sales:</div>
          <div className="text-right">{formatAmount(monthlyReport.sales)}</div>
          <div>Surcharge:</div>
          <div className="text-right">
            {formatAmount(monthlyReport.convenienceFees)}
          </div>
          <div>Technology:</div>
          <div className="text-right">
            {formatAmount(monthlyReport.techFees)}
          </div>
          <div>(less) Refunds:</div>
          <div className="text-right underline">
            {formatAmount(monthlyReport.summaryRefundsTotal)}
          </div>
          <div>Net Sales:</div>
          <div className="text-right">
            {formatAmount(monthlyReport.netSales)}
          </div>
          <div>(less) Processing:</div>
          <div className="text-right">
            {formatAmount(monthlyReport.processingFees)}
          </div>
          <div>(less) Technology:</div>
          <div className="text-right">
            {formatAmount(monthlyReport.techFees)}
          </div>
          <div>(less) Chargebacks:</div>
          <div className="text-right underline">
            {formatAmount(monthlyReport.chargebacks)}
          </div>
          <div>Net:</div>
          <div className="text-right">
            {formatAmount(monthlyReport.deposit)}
          </div>
        </div>
        <div className="self-center">
          <Button
            onClick={() => {
              logCSVInteraction("Monthly", currentUserAccount?.username);
              setMonthlyReportCSVQueryEnabled(true);
            }}
            size="sm"
            variant="secondary"
            disabled={isFetchingReportCSV}
            className="px-3 py-1 print:hidden"
            icon={isFetchingReportCSV ? <Spinner /> : <ArrowDownTrayIcon />}
          >
            Download Transactions
          </Button>
        </div>
      </div>

      {/* <>
        {monthlyReport.additionalServiceFeeList && (
          <>
            <h3 className="my-2 text-2xl">Additional Service Fees</h3>
            <ReportTable
              columns={[
                { label: "Fee Type" },
                { label: "Quantity", className: alignRight },
                { label: "Per Transaction Fee", className: alignRight },
                { label: "Total", className: alignRight },
              ]}
              data={additionalServiceFeesData}
              totalData={[
                "Total Fees DUE:",
                "",
                "",
                formatAmount(monthlyReport.totalFeesDue),
              ]}
              tableClasses="w-3/4 self-center"
            />
          </>
        )}
        <div className="text-3xl print:text-xl">
          {monthlyReport.totalFeesDue <= 0
            ? "No Charge as Credit Balance."
            : `${formatAmount(monthlyReport.totalFeesDue)} will be deducted from
          your account.`}
        </div>
      </> */}

      <footer className="my-20 flex w-full justify-between text-xl">
        <div>www.JackrabbitPay.com</div>
        <div>
          All transactions are in {accountConfigData?.timeZoneCode} Time zone
        </div>
        <div className="flex">
          All values are in {monthlyReport.currencyCode}
        </div>
      </footer>
    </div>
  );
}

export default MonthlyReportDetail;
