import useAccountHoldersQuery from "../hooks/data/queries/useAccountHoldersQuery";
import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function ClientStats(): JSX.Element {
  const [localSorting] = useState<SortingState>([]);
  const [allClientLocations, setAllClientLocationCount] = useState(0);
  const [pendingClientLocations, setPendingClientLocationCount] = useState(0);

  useAccountHoldersQuery(
    {
      accountHolderCode: "",
      status: "",
      pageNumber: 1,
      pageSize: 10000,
      sort: localSorting,
    },
    {
      onSuccess: (data) => {
        const openData = data.pageElements.filter(
          (d) => d.merchantStatus && d.merchantStatus.toLowerCase() !== "closed"
        );

        setAllClientLocationCount(openData.length);
        setPendingClientLocationCount(
          openData.filter(
            (d) => d.jrPayStatus && d.jrPayStatus.toLowerCase() === "false"
          ).length
        );
      },
    }
  );

  return (
    <div className="up2rem flex flex-wrap gap-x-10">
      <div className="gap-x-tight flex flex-wrap items-center items-stretch">
        <div className="mx-auto rounded bg-primary text-white">
          <Tippy placement="top" content="Client locations processing">
            <dl>
              <div className="mx-auto flex max-w-xs flex-col text-center">
                <dt className="text-md px-4 font-semibold leading-8">
                  Active Clients
                </dt>
                <hr />
                <dd className="px-2 text-sm font-semibold leading-6">
                  {allClientLocations - pendingClientLocations}
                </dd>
              </div>
            </dl>
          </Tippy>
        </div>
        <div className="mx-auto rounded bg-primary text-white">
          <dl>
            <div className="mx-auto flex max-w-xs flex-col text-center">
              <dt className="text-md px-4 font-semibold leading-8">Pending</dt>
              <hr />
              <dd className="px-2 text-sm font-semibold leading-6">
                {pendingClientLocations}
              </dd>
            </div>
          </dl>
        </div>
        <div className="mx-auto rounded bg-primary text-white">
          <dl>
            <div className="mx-auto flex max-w-xs flex-col text-center">
              <dt className="text-md px-4 font-semibold leading-8">Total</dt>
              <hr />
              <dd className="px-2 text-sm font-semibold leading-6">
                {allClientLocations}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default ClientStats;
