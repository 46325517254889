import { twMerge } from "tailwind-merge";
import { Switch as OriginalSwitch } from "@headlessui/react";

type Props = {
  checked: boolean;
  onChange: () => void;
  label: string;
  disabled?: boolean;
  showLegend?: boolean;
  className?: string;
  containerProps?: React.HTMLAttributes<HTMLElement>;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
};

function Switch({
  checked,
  onChange,
  label,
  disabled = false,
  showLegend = true,
  className = "",
  containerProps,
  labelProps,
}: Props): JSX.Element {
  function getBackgroundClass() {
    let bgColor = checked ? "bg-primary" : "bg-gray-400";
    return disabled ? `${bgColor} cursor-not-allowed` : bgColor;
  }

  return (
    <OriginalSwitch.Group>
      <div
        {...containerProps}
        className={twMerge("flex items-center", containerProps?.className)}
      >
        <OriginalSwitch.Label
          {...labelProps}
          onClick={(e: React.MouseEvent) => e.preventDefault()}
          className={twMerge("mr-4", labelProps?.className)}
        >
          {label}
        </OriginalSwitch.Label>
        <OriginalSwitch
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={twMerge(
            "relative inline-flex h-6 w-11 items-center rounded-full",
            getBackgroundClass(),
            className
          )}
        >
          <span className="sr-only">{label}</span>
          <span
            className={twMerge(
              "inline-block h-4 w-4 transform rounded-full bg-white transition",
              checked ? "translate-x-6" : "translate-x-1"
            )}
          />
        </OriginalSwitch>
        {showLegend && <span className="ml-4">{checked ? "On" : "Off"}</span>}
      </div>
    </OriginalSwitch.Group>
  );
}

export default Switch;
