import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../../components/Checkbox";
import { useSnackbar } from "../../components/Snackbar";
import useUpdateAllowedPaymentMehtodsMutation from "../../hooks/data/mutations/useUpdateAllowedPaymentMethodsMutation";
import paymentMethods from "../../data/paymentMethods.json";
import Panel from "./Panel";
import EditControls from "./EditControls";

type Props = {
  accountHolderCode: string;
  accountHolderId: string;
  allowedPaymentMethods: string[] | undefined;
  isLoading: boolean;
};

function AllowedPaymentMethods({
  accountHolderCode,
  accountHolderId,
  allowedPaymentMethods,
  isLoading,
}: Props): JSX.Element {
  const {
    mutate: updateAllowedPaymentMethods,
    isLoading: isUpdatingPaymentMethods,
  } = useUpdateAllowedPaymentMehtodsMutation(accountHolderCode);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedAllowedPaymentMethods, setEditedAllowedPaymentMethods] =
    useState<string[]>([]);

  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });

  const firstAllowedPaymentMethodCheckboxRef = useRef<HTMLInputElement>(null);

  const saveButtonEnabled =
    JSON.stringify(allowedPaymentMethods?.sort()) !==
    JSON.stringify(editedAllowedPaymentMethods.sort());

  useEffect(() => {
    if (allowedPaymentMethods) {
      setEditedAllowedPaymentMethods(allowedPaymentMethods);
    }
  }, [allowedPaymentMethods]);

  useEffect(() => {
    firstAllowedPaymentMethodCheckboxRef.current?.focus();
  }, [isEditing]);

  function handleItemChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.target;

    if (checked) {
      if (!editedAllowedPaymentMethods.includes(name)) {
        setEditedAllowedPaymentMethods([...editedAllowedPaymentMethods, name]);
      }
    } else {
      if (editedAllowedPaymentMethods.includes(name)) {
        setEditedAllowedPaymentMethods(
          editedAllowedPaymentMethods.filter((pm) => pm !== name)
        );
      }
    }
  }

  function handleUpdateAllowedPaymentMethods() {
    updateAllowedPaymentMethods(
      {
        allowedPayments: editedAllowedPaymentMethods.map((pm) => ({
          accountHolderId,
          allowedPaymentMethod: pm,
        })),
      },
      {
        onSuccess() {
          openSnackbar("Allowed payment methods were successfully updated", {
            type: "success",
          });
          setIsEditing(false);
        },
        onError() {
          openSnackbar(
            "Failed to update allowed payment methods. Please try again.",
            {
              type: "error",
            }
          );
        },
      }
    );
  }

  return (
    <Panel className="col-span-4">
      <Panel.Header title="Allowed Payment Methods">
        <EditControls
          customId="editPaymentMethodsButton"
          isEditing={isEditing}
          isSaving={isUpdatingPaymentMethods}
          editButtonProps={{
            disabled: isLoading,
            onClick: () => {
              setIsEditing(true);
            },
          }}
          saveButtonProps={{
            onClick: handleUpdateAllowedPaymentMethods,
            disabled: !saveButtonEnabled,
          }}
          cancelButtonProps={{
            onClick: () => {
              setIsEditing(false);
              setEditedAllowedPaymentMethods(allowedPaymentMethods || []);
            },
          }}
        />
      </Panel.Header>
      <Panel.Content>
        <div className="flex flex-col gap-y-4 gap-x-4">
          {paymentMethods
            .sort(function (a, b) {
              if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;
              if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;
              return 0;
            })
            .map((pm, i) => (
              <Checkbox
                ref={i === 0 ? firstAllowedPaymentMethodCheckboxRef : null}
                key={pm.value}
                id={`payment${pm.label}`}
                checked={editedAllowedPaymentMethods?.some((epm) => {
                  return epm === pm.value;
                })}
                name={pm.value}
                onChange={handleItemChange}
                label={pm.label}
                disabled={!isEditing}
              />
            ))}
        </div>
      </Panel.Content>
    </Panel>
  );
}

export default AllowedPaymentMethods;
