import { useNavigate, useParams } from "react-router-dom";
import useTransactionDetailQuery from "../../hooks/data/queries/useTransactionDetailQuery";
import TransactionSummary from "./TransactionSummary";
import TransactionACHDetail from "./TransactionACHDetail";
import TransactionCardDetail from "./TransactionCardDetail";
import TransactionShopperInfo from "./TransactionShopperInfo";
import Transaction3DSDetail from "./Transaction3DSDetail";
import TransactionPaymentProcessing from "./TransactionPaymentProcessing";
import TransactionNotes from "./Notes/TransactionNotes";
import Error from "../../components/Error";
import TransactionLifecycle from "./Lifecycle/TransactionLifecycle";
import TransactionEvents from "./TransactionEvents";
import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import CancelTransactionModal from "./CancelTransactionModal";
import RefundTransactionModal from "./RefundTransactionModal";
import TransactionPaymentFee from "./TransactionPaymentFee";
import HasAccess, {
  csrAdminRoles,
  csrRoles,
  customerRoles,
} from "../../components/HasAccess";
import { DateTime } from "luxon";
import { longDateFormat } from "../../utils/dateTime";

type TransactionDetailsParams = {
  pspReference: string;
};

type Props = {
  pspReference?: string;
};

function Transaction({ pspReference: pspReferenceProp }: Props): JSX.Element {
  const { pspReference } = useParams<TransactionDetailsParams>();
  const {
    data: transaction,
    error,
    isLoading,
    isFetching,
    isError,
  } = useTransactionDetailQuery(pspReference || pspReferenceProp || "");

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);

  const isACHTransaction = transaction?.paymentMethod === "ach";
  const isLoadingDetails =
    isLoading || (isFetching && !transaction?.paymentDetails);

  const pageRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    pageRef.current?.scrollIntoView({
      block: "start",
      inline: "start",
    });
  }, []);

  const transactionCreatedDate = DateTime.fromFormat(
    transaction?.transactionCreatedDate ?? "",
    longDateFormat
  );
  const paymentHasMoreThan5Days =
    transactionCreatedDate.plus({
      days: 5,
    }) < DateTime.now();

  const paymentEvents = transaction?.paymentEvents;
  const hasAuthorizationFailedEvent = paymentEvents?.some(
    (x) => x.eventType === "Authorisation" && !x.success
  );
  const hasFailedOrCancellationEvent = paymentEvents?.some(
    (x) => x.eventType === "Failed" || x.eventType === "Cancellation"
  );

  const showCancel =
    transaction?.paymentLifeCycleStatus === "Authorised" &&
    !hasAuthorizationFailedEvent &&
    !hasFailedOrCancellationEvent;

  const hasChargebackEvent = paymentEvents?.some(
    (x) => x.eventType === "Chargeback"
  );

  const isFullyRefunded =
    transaction?.amountValueRaw === transaction?.refundAmountRaw ||
    +(
      (transaction?.amountValueRaw ?? 0) -
      (transaction?.paymentDetails?.technologyFeeRaw ?? 0)
    ).toFixed(2) === transaction?.refundAmountRaw;

  const showRefund =
    (transaction?.paymentLifeCycleStatus === "SentForSettle" ||
      transaction?.paymentLifeCycleStatus === "Settled" ||
      transaction?.paymentLifeCycleStatus === "Refunded") &&
    !isFullyRefunded &&
    (isACHTransaction ? paymentHasMoreThan5Days : true) &&
    !hasChargebackEvent;

  return (
    <div ref={pageRef} className="w-full scroll-m-24 p-4 xl:px-24">
      <h1 className="mb-3 text-3xl">Payment Details</h1>
      <div className="mb-3 flex items-center justify-between">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center text-primary-700 underline"
        >
          Back to Transactions
        </button>
        {showCancel && !isError && (
          <HasAccess allowedRoles={[...csrAdminRoles, ...customerRoles]}>
            <Button
              onClick={() => setShowCancelModal(true)}
              disabled={transaction.lockReason !== null}
              title={transaction?.lockReason ?? ""}
            >
              Cancel Payment
            </Button>
          </HasAccess>
        )}
        {showRefund && !isError && (
          <HasAccess allowedRoles={[...csrAdminRoles, ...customerRoles]}>
            <Button
              onClick={() => setShowRefundModal(true)}
              disabled={transaction.lockReason !== null}
              title={transaction?.lockReason ?? ""}
            >
              Refund Payment
            </Button>
          </HasAccess>
        )}
      </div>

      <CancelTransactionModal
        isOpen={showCancelModal}
        pspReference={pspReference!}
        merchantAccount={transaction?.merchantAccount!}
        onClose={() => setShowCancelModal(false)}
      />

      {transaction && (
        <RefundTransactionModal
          isOpen={showRefundModal}
          transaction={transaction}
          onClose={() => setShowRefundModal(false)}
        />
      )}

      {error ? (
        <Error message={error} />
      ) : (
        <>
          <TransactionSummary transaction={transaction} isLoading={isLoading} />
          <div className="flex flex-wrap gap-x-3 lg:gap-x-4 2xl:gap-x-6">
            {isACHTransaction ? (
              <TransactionACHDetail
                transactionDetail={transaction?.paymentDetails}
                isLoading={isLoadingDetails}
              />
            ) : (
              <TransactionCardDetail
                transactionDetail={transaction?.paymentDetails}
                isLoading={isLoadingDetails}
              />
            )}
            <TransactionShopperInfo
              transactionDetail={transaction?.paymentDetails}
              isLoading={isLoadingDetails}
            />
            <HasAccess allowedRoles={[...csrRoles]}>
              <TransactionPaymentFee
                transaction={transaction}
                transactionDetail={transaction?.paymentDetails}
                isLoading={isLoadingDetails}
              />
            </HasAccess>
            <TransactionPaymentProcessing
              transaction={transaction}
              transactionDetail={transaction?.paymentDetails}
              isLoading={isLoadingDetails}
            />
            {!isACHTransaction && (
              <Transaction3DSDetail
                transactionDetail={transaction?.paymentDetails}
                isLoading={isLoadingDetails}
              />
            )}
            <TransactionLifecycle
              transactionLifecycles={transaction?.paymentLifeCycles ?? []}
              timeZoneCode={transaction?.accountHolderTimeZone?.code ?? ""}
              isLoading={isLoadingDetails}
            />
            <TransactionEvents
              transactionEvents={transaction?.paymentEvents ?? []}
              timeZoneCode={transaction?.accountHolderTimeZone?.code ?? ""}
              isLoading={isLoadingDetails}
            />
            <HasAccess allowedRoles={[...csrRoles]}>
              <TransactionNotes
                pspReference={pspReference!}
                transactionNotes={transaction?.paymentNotes ?? []}
                timeZoneCode={transaction?.accountHolderTimeZone?.code ?? ""}
                isLoading={isLoading}
                isFetching={isFetching}
              />
            </HasAccess>
          </div>
        </>
      )}
    </div>
  );
}

export default Transaction;
